import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { countBy } from "lodash";
import {
  Box,
  Button,
  createStyles,
  makeStyles,
  Paper,
  Theme,
  Typography,
} from "@material-ui/core";
import { TableCustom } from "components/table/TableCustom";
import { DWOJobModel } from "@dwo/shared/dist/models/DWOJobModel";
import { JobHazardEmployeeData } from "@dwo/shared/dist/models/DWOModel";
import { WorkShiftModel } from "@dwo/shared/dist/models/workShiftModel";
import { ServiceOptions } from "@dwo/shared/dist/services/baseService";
import {
  editCrewOverviewColumns,
  findUpdatedValue,
  getEditCrewOverviewRows,
} from "components/timesheetDetails/crewOverviewUtils";
import {
  decimalTimeToHoursTime,
  slashFormatDateWithHoursMinutes,
} from "utils/dateUtils";
import {
  DwoStatusLabels,
  DwoStatusValues,
} from "utils/timesheetsManagementUtils";
import {
  approveDWO,
  createWorkShift,
  getDWOJobById,
  selectCurrentDWO,
  selectCurrentDWOJob,
  selectCurrentJob,
  selectDWOCrewDetailsByJobId,
  selectIsLoadingDWODetails,
  selectIsLoadingDWOJob,
  selectTempWorkshifts,
  updateWorkShifts,
} from "features/dwo/dwoSlice";
import {
  clearValues,
  selectIsTimeOffChecked,
  selectValues,
  setIsTimeOffChecked,
  setIsTimeOffDisabled,
  WorkShiftTableCellValue,
} from "features/editWorkShiftTableSlice/workShiftTableValuesSlice";
import { prompt, selectResponse } from "features/prompt/promptSlice";
import { selectCurrentUser } from "features/logIn/sessionSlice";
import { TempWorkshiftSection } from "./TempWorkshiftSection";

interface EditCrewOverviewTableProps {
  jobHazardEmployeeData?: JobHazardEmployeeData[];
  sortingOptions: string[][];
  onClickSort: (sortingValues: string[][]) => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      backgroundColor: "white",
      borderRadius: "0px",
      margin: "0 24px 24px 24px",
      padding: "0 24px",
      position: "relative",
      zIndex: 2,
    },
    approvedText: {
      color: theme.palette.grey[600],
      display: "flex",
      justifyContent: "flex-end",
      paddingBottom: 12,
    },
  }),
);

export function EditCrewOverviewTable({
  jobHazardEmployeeData = [],
  sortingOptions,
  onClickSort,
}: EditCrewOverviewTableProps) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { id } = useParams<{ id: string }>();
  const dwoId = parseInt(id, 10);
  const currentDWOJob: DWOJobModel | undefined = useSelector(
    selectCurrentDWOJob,
  );
  const currentDWO = useSelector(selectCurrentDWO);
  const currentJob = useSelector(selectCurrentJob);
  const currentUser = useSelector(selectCurrentUser);
  const dwoCrewDetailsByJobId = useSelector(selectDWOCrewDetailsByJobId);
  const isLoadingDWOCrewDetails = useSelector(selectIsLoadingDWODetails);
  const isLoadingDWOJob = useSelector(selectIsLoadingDWOJob);
  const promptResponse = useSelector(selectResponse);
  const values = useSelector(selectValues);
  const isTimeOffChecked = useSelector(selectIsTimeOffChecked);
  const temporalWorkshifts = useSelector(selectTempWorkshifts);
  const [dwoJobId, setDwoJobId] = useState<number>(-1);
  const [approveButtonLabel, setApproveButtonLabel] = useState<string>("");
  const [employeesInWorkShift, setEmployeesInWorkShift] = useState<
    WorkShiftModel[]
  >([]);
  const [isEditModeActive, setIsEditModeActive] = useState(false);
  const [isUpdateDisabled, setIsUpdateDisabled] = useState(true);
  const isLoading = isLoadingDWOCrewDetails || isLoadingDWOJob;

  const isApproveDisabled =
    approveButtonLabel === "" ||
    (approveButtonLabel === DwoStatusLabels.APPROVED &&
      currentUser?.employee.role !== "admin") ||
    currentDWO?.areButtonsDisable === true ||
    temporalWorkshifts.length > 0;

  useEffect(() => {
    if (dwoCrewDetailsByJobId && dwoCrewDetailsByJobId.workShifts) {
      const tableWorkshifts: WorkShiftModel[] = dwoCrewDetailsByJobId.workShifts.reduce(
        (workshifts: WorkShiftModel[], currentWorkshift: WorkShiftModel) => {
          if (
            currentWorkshift &&
            currentWorkshift.employee.role !== "supervisor"
          ) {
            return [...workshifts, currentWorkshift];
          }

          return workshifts;
        },
        [],
      );

      const dwoJobWorkshift = tableWorkshifts.find(
        (workshift: WorkShiftModel) => workshift.dwoJobId,
      );

      if (dwoJobWorkshift && dwoJobWorkshift.dwoJobId) {
        const options: ServiceOptions = {
          include: [{ updatedBy: ["employee"] }],
        };
        dispatch(getDWOJobById(dwoJobWorkshift.dwoJobId, options));
      }

      setEmployeesInWorkShift(tableWorkshifts);
      return;
    }

    setEmployeesInWorkShift([]);
  }, [dwoCrewDetailsByJobId, dispatch]);

  useEffect(() => {
    if (!currentDWOJob || !currentJob) {
      setApproveButtonLabel("");
      return;
    }

    if (
      currentUser?.employee.role === ("admin" || "supervisor") &&
      currentDWOJob.status === DwoStatusValues.APPROVED
    ) {
      setApproveButtonLabel(DwoStatusLabels.REAPPROVE);
      return;
    }

    if (currentDWOJob.status === DwoStatusValues.APPROVED) {
      setApproveButtonLabel(DwoStatusLabels.APPROVED);
      return;
    }

    setApproveButtonLabel(
      `Approve ${decimalTimeToHoursTime(currentJob.hours as number)}`,
    );
  }, [currentDWOJob, currentJob]);

  useEffect(() => {
    if (!isEditModeActive) {
      dispatch(clearValues());
    }

    if (!isTimeOffChecked) {
      dispatch(clearValues());
    }
  }, [isEditModeActive, isTimeOffChecked, dispatch]);

  useEffect(() => {
    dispatch(setIsTimeOffDisabled(isEditModeActive));
  }, [isEditModeActive, dispatch]);

  useEffect(() => {
    if (currentDWOJob && dwoJobId !== -1 && promptResponse) {
      dispatch(approveDWO(currentDWOJob));
      setDwoJobId(-1);
    }
  }, [currentDWOJob, dwoJobId, promptResponse, dispatch]);

  useEffect(() => {
    const ids = values.map((value) => [value.idRow]);
    const countById = Object.values(countBy(ids));
    setIsUpdateDisabled(
      countById.length > 0
        ? !countById.every((count) => count % 5 === 0)
        : true,
    );
  }, [values]);

  const handleClickApprove = () => {
    if (
      currentDWOJob &&
      currentDWOJob.status === DwoStatusValues.APPROVED &&
      currentUser?.employee.role === "admin" &&
      currentJob
    ) {
      setDwoJobId(currentDWOJob.id as number);
      dispatch(
        prompt({
          title: "CAUTION, You Are About To Approve This Again.",
          message: `You can edit this timesheet and re-approve it. The current total time is ${
            decimalTimeToHoursTime(currentJob?.hours) || 0
          }, continue?`,
        }),
      );
      return;
    }

    if (
      currentDWOJob &&
      currentDWOJob.status !== DwoStatusValues.APPROVED &&
      currentJob
    ) {
      setDwoJobId(currentDWOJob.id as number);
      dispatch(
        prompt({
          title: "You Are About To Approve This Timesheet.",
          message: `Do you want to continue to approve the total time of ${
            decimalTimeToHoursTime(currentJob?.hours) || 0
          } hours for this timesheet?`,
        }),
      );
    }
  };

  const handleClickEdit = () => {
    if (isTimeOffChecked && !isEditModeActive) {
      dispatch(setIsTimeOffChecked(false));
    }

    if (isEditModeActive && !isTimeOffChecked) {
      setIsEditModeActive(false);
    }

    if (!isEditModeActive && !isTimeOffChecked) {
      setIsEditModeActive(true);
    }
  };

  const handleClickUpdate = () => {
    if (values.length > 0 && dwoCrewDetailsByJobId?.workShifts) {
      const valuesById = values.reduce(
        (object: Record<number, WorkShiftTableCellValue[]>, value) => {
          object[value.idRow] = [...(object[value.idRow] || []), value];
          return object;
        },
        {},
      );

      const existingWorkShifts = dwoCrewDetailsByJobId.workShifts.filter(
        (workShift) =>
          workShift.employeeId ===
          values.find((value) => value.idRow === workShift.employeeId)?.idRow,
      );
      const getValuesById = (id: number) => valuesById[id];

      if (existingWorkShifts.length > 0) {
        const updatedExistingWorkShifts = existingWorkShifts.reduce(
          (updatedWorkShifts: WorkShiftModel[], workShift) => {
            const workShiftValues = valuesById[workShift.employeeId];

            if (workShiftValues) {
              const startValue = findUpdatedValue(workShiftValues, "start")
                ?.inputValue as string;
              const endValue = findUpdatedValue(workShiftValues, "end")
                ?.inputValue as string;
              const typeValue = findUpdatedValue(workShiftValues, "type")
                ?.inputValue as string;
              const lunchTimeValue = findUpdatedValue(workShiftValues, "lunch")
                ?.inputValue as string;
              const timeBreaksValues: any = workShift.timeBreaks ?? [];
              const phaseCodeValue = findUpdatedValue(
                workShiftValues,
                "phaseCode",
              )?.inputValue as string;

              const updatedWorkShift = {
                id: workShift.id,
                date: workShift.date,
                dwoJobId: workShift.dwoJobId,
                employeeId: workShift.employeeId,
                type: typeValue,
                start: new Date(startValue).toISOString(),
                end: new Date(endValue).toISOString(),
                lunchTime: lunchTimeValue,
                phaseCode: phaseCodeValue,
                timeBreaksIds: timeBreaksValues.map((ws: any) => ws.id),
              };
              updatedWorkShifts.push(updatedWorkShift as any);
            }

            return updatedWorkShifts;
          },
          [],
        );

        if (updatedExistingWorkShifts.length > 0 && currentJob) {
          dispatch(
            updateWorkShifts(
              { workshifts: updatedExistingWorkShifts },
              dwoId,
              parseInt(currentJob.id as string, 10),
              updatedExistingWorkShifts,
            ),
          );
        }
      }

      const allValuesToCreateWorkShift = values.filter(
        (value) =>
          !existingWorkShifts.find(
            (workShift) => workShift.employeeId === value.idRow,
          ),
      );

      const employeeIdsToCreateWorkShift = Array.from(
        new Set(allValuesToCreateWorkShift.map((value) => value.idRow)),
      );

      const valuesGroupedByIdToCreateWorkShift = employeeIdsToCreateWorkShift.map(
        (id) => getValuesById(id),
      );

      if (valuesGroupedByIdToCreateWorkShift.length > 0 && currentJob) {
        const newWorkShifts = valuesGroupedByIdToCreateWorkShift.map(
          (value) => {
            const startValue = findUpdatedValue(value, "start")
              ?.inputValue as string;
            const endValue = findUpdatedValue(value, "end")
              ?.inputValue as string;
            const typeValue = findUpdatedValue(value, "type")
              ?.inputValue as string;
            // const lunchTimeValue = findUpdatedValue(value, "lunch")
            //   ?.inputValue as string;

            return {
              dwoJobId: currentJob.dwoJobId,
              employeeId: findUpdatedValue(value, "start")?.idRow,
              start: new Date(startValue).toISOString(),
              end: new Date(endValue).toISOString(),
              // lunchTime: lunchTimeValue,
              type: typeValue,
            };
          },
        );

        newWorkShifts.forEach((workShift) => {
          dispatch(
            createWorkShift(
              workShift as WorkShiftModel,
              dwoId,
              Number(currentJob.jobId),
            ),
          );
        });
      }
      setIsEditModeActive(false);
      dispatch(setIsTimeOffChecked(false));
    }
  };

  return (
    <Paper className={classes.paper}>
      <Box display="flex" justifyContent="flex-end" marginBottom="16px">
        <Typography>Trip history:</Typography>
        <Typography style={{ paddingLeft: "6px" }}>
          {dwoCrewDetailsByJobId &&
          dwoCrewDetailsByJobId.samsaraUrl &&
          dwoCrewDetailsByJobId.samsaraUrl.url !== "" ? (
            <a
              href={dwoCrewDetailsByJobId.samsaraUrl.url}
              target="_blank"
              rel="noopener noreferrer"
            >
              Link to samsara
            </a>
          ) : (
            " - - -"
          )}
        </Typography>
      </Box>
      {employeesInWorkShift && jobHazardEmployeeData && currentJob && (
        <TableCustom
          columns={editCrewOverviewColumns(
            isTimeOffChecked || isEditModeActive,
          )}
          isLoading={isLoading}
          noRowsMessage="There are no crew timesheets to display."
          rows={getEditCrewOverviewRows(
            employeesInWorkShift,
            jobHazardEmployeeData,
            isTimeOffChecked || isEditModeActive,
            isTimeOffChecked,
            values,
            Number(currentJob.id || 0)
          )}
          sortingOptions={sortingOptions}
          onClickSort={onClickSort}
        />
      )}
      {employeesInWorkShift && employeesInWorkShift.length > 0 && !isLoading && (
        <Fragment>
          <Box
            display="flex"
            width="100%"
            justifyContent="flex-end"
            padding="24px 0 12px"
          >
            <Box width="150px">
              <Button
                disabled={currentDWO?.areButtonsDisable === true}
                fullWidth
                onClick={handleClickEdit}
                variant="outlined"
              >
                {isTimeOffChecked || isEditModeActive ? "Cancel" : "Edit"}
              </Button>
            </Box>
            {isTimeOffChecked || isEditModeActive ? (
              <Box width="150px" marginLeft="24px">
                <Button
                  disabled={isUpdateDisabled}
                  fullWidth
                  onClick={handleClickUpdate}
                  variant="contained"
                >
                  Update
                </Button>
              </Box>
            ) : (
              <Box width="150px" marginLeft="24px">
                <Button
                  disabled={isApproveDisabled}
                  fullWidth
                  onClick={handleClickApprove}
                  variant="contained"
                >
                  {approveButtonLabel}
                </Button>
              </Box>
            )}
          </Box>
          {currentDWOJob &&
            currentDWOJob.status === DwoStatusValues.APPROVED && (
              <Box className={classes.approvedText}>{`Last approved by ${
                currentDWOJob.updatedBy?.employee?.fullName
              }, ${
                currentDWOJob.updatedAt &&
                slashFormatDateWithHoursMinutes(
                  new Date(currentDWOJob.updatedAt as string),
                )
              }`}</Box>
            )}
        </Fragment>
      )}
      {!isLoading && <TempWorkshiftSection regionId={currentJob?.regionId} />}
    </Paper>
  );
}
