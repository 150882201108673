import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppThunk, RootState } from "app/store";
import { cloneDeep, noop } from "lodash";
import moment from "moment";
import { error } from "features/error/errorSlice";
import {
  hide as hideLoader,
  show as showLoader,
} from "features/loader/loaderSlice";
import {
  DWOEmployeeModel,
  DWOModel,
  JobHazardEmployeeData,
} from "@dwo/shared/dist/models/DWOModel";
import { DWOJobModel } from "@dwo/shared/dist/models/DWOJobModel";
import { EmployeeModel } from "@dwo/shared/dist/models/employeeModel";
import { JobModel } from "@dwo/shared/dist/models/jobModel";
import {
  TimeBreakStatus,
  TimeBreakType,
} from "@dwo/shared/dist/models/timeBreakModel";
import {
  WorkShiftModel,
  WorkShiftPictureMatchStatus,
  WorkShiftRequestModel,
  WorkShiftStatus,
} from "@dwo/shared/dist/models/workShiftModel";
import { crewService } from "@dwo/shared/dist/services/crewService";
import { dailyJobHazardService } from "@dwo/shared/dist/services/dailyJobHazardService";
import { dwoJobService } from "@dwo/shared/dist/services/dwoJobService";
import { dwoService } from "@dwo/shared/dist/services/dwoService";
import { EmployeeCrewModel } from "@dwo/shared/dist/models/employeeCrewModel";
import { employeeService } from "@dwo/shared/dist/services/employeeService";
import { jobService } from "@dwo/shared/dist/services/JobService";
import { ServiceOptions } from "@dwo/shared/dist/services/baseService";
import { TempWorkshiftModel } from "@dwo/shared/dist/models/tempWorkshiftModel";
import { tempWorkshiftService } from "@dwo/shared/dist/services/tempWorkshiftService";
import { timeBreakService } from "@dwo/shared/dist/services/timeBreakService";
import { userService } from "@dwo/shared/dist/services/userService";
import { workShiftService } from "@dwo/shared/dist/services/workShiftService";
import {
  CrewFilters,
  EmployeeFilters,
  initialCrewFilters,
  initialCrewQuery,
  initialEmployeeFilters,
  initialEmployeeQuery,
  TimeSheetTabs,
} from "utils/timesheetsManagementUtils";
import { JobTabs } from "utils/jobUtils";
import { DEFAULT_LIMIT } from "utils/sharedUtils";
import { JobStatusValues } from "utils/jobUtils";
import { CrewModel } from "@dwo/shared/dist/models/crewModel";

// TODO: remove if coordinated comes from backend
export const LOCATION = "32.65722450278615,-97.28580707104494";

interface dwoState {
  count: number;
  currentDWO?: DWOModel;
  currentDWOJob?: DWOJobModel;
  currentJob?: JobModel;
  dwoByForemanIdAndDate?: DWOModel;
  dwoList: DWOModel[];
  dwoCrewDetails?: DWOModel;
  dwoCrewDetailsByJobId?: DWOModel;
  jobHazardEmployeeData?: JobHazardEmployeeData[];
  dwoJobs: DWOJobModel[];
  dwoEmployeeList: DWOEmployeeModel[];
  jobs: JobModel[];
  foremanJobs: JobModel[];
  initialJobId?: number | string;
  isLoadingDWO: boolean;
  isLoadingDWOJob: boolean;
  isLoadingDWOJobs: boolean;
  isLoadingDWOList: boolean;
  isLoadingDWODetails: boolean;
  isLoadingDWOEmployeeList: boolean;
  isLoadingForemanJobs: boolean;
  isLoadingTempWorkshifts: boolean;
  isLoadingDwoJobCrew: boolean;
  limit?: number;
  order?: any;
  selectedTab: number;
  foremanId?: number;
  tempWorkshifts: TempWorkshiftModel[];
  dwoJobCrew: EmployeeCrewModel[];
  tabSelected: TimeSheetTabs;
  jobPerformanceTabSelected: JobTabs;
  crewFilters: CrewFilters;
  crewQuery: ServiceOptions;
  employeeFilters: EmployeeFilters;
  employeeQuery: ServiceOptions;
  refreshVeiw: boolean;
}

const initialState: dwoState = {
  count: 0,
  currentDWO: undefined,
  currentDWOJob: undefined,
  currentJob: undefined,
  dwoByForemanIdAndDate: undefined,
  dwoList: [],
  dwoCrewDetails: undefined,
  dwoCrewDetailsByJobId: undefined,
  dwoJobs: [],
  dwoEmployeeList: [],
  jobs: [],
  foremanJobs: [],
  initialJobId: undefined,
  isLoadingDWO: false,
  isLoadingDWOJob: false,
  isLoadingDWOJobs: false,
  isLoadingDWOList: false,
  isLoadingDWODetails: false,
  isLoadingDWOEmployeeList: false,
  isLoadingForemanJobs: false,
  isLoadingTempWorkshifts: false,
  isLoadingDwoJobCrew: false,
  limit: DEFAULT_LIMIT,
  order: undefined,
  selectedTab: 0,
  foremanId: undefined,
  tempWorkshifts: [],
  dwoJobCrew: [],
  tabSelected: TimeSheetTabs.Crew,
  jobPerformanceTabSelected: JobTabs.ALL,
  crewFilters: initialCrewFilters,
  crewQuery: initialCrewQuery,
  employeeFilters: initialEmployeeFilters,
  employeeQuery: initialEmployeeQuery,
  refreshVeiw: false,
};

export const dwoSlice = createSlice({
  name: "dwo",
  initialState,
  reducers: {
    setCount: (state, action: PayloadAction<number>) => {
      state.count = action.payload;
    },
    setCurrentDWO: (state, action: PayloadAction<DWOModel | undefined>) => {
      state.currentDWO = action.payload;
    },
    setCurrentDWOJob: (
      state,
      action: PayloadAction<DWOJobModel | undefined>,
    ) => {
      state.currentDWOJob = action.payload;
    },
    setCurrentJob: (state, action: PayloadAction<JobModel | undefined>) => {
      state.currentJob = action.payload;
    },
    setDWOList: (state, action: PayloadAction<DWOModel[]>) => {
      state.dwoList = action.payload;
    },
    setDWOCrewDetails: (state, action: PayloadAction<DWOModel | undefined>) => {
      state.dwoCrewDetails = action.payload;
    },
    setDWOCrewDetailsByJobId: (
      state,
      action: PayloadAction<DWOModel | undefined>,
    ) => {
      state.dwoCrewDetailsByJobId = action.payload;
    },
    setJobHazardEmployeeData: (
      state,
      action: PayloadAction<JobHazardEmployeeData[] | undefined>,
    ) => {
      state.jobHazardEmployeeData = action.payload;
    },
    setDWOJobs: (state, action: PayloadAction<DWOJobModel[]>) => {
      state.dwoJobs = action.payload;
    },
    setDWOEmployeeList: (state, action: PayloadAction<DWOEmployeeModel[]>) => {
      state.dwoEmployeeList = action.payload;
    },
    setInitialJobId: (
      state,
      action: PayloadAction<number | string | undefined>,
    ) => {
      state.initialJobId = action.payload;
    },
    setIsLoadingDWO: (state, action: PayloadAction<boolean>) => {
      state.isLoadingDWO = action.payload;
    },
    setIsLoadingDWOJob: (state, action: PayloadAction<boolean>) => {
      state.isLoadingDWOJob = action.payload;
    },
    setIsLoadingDWOJobs: (state, action: PayloadAction<boolean>) => {
      state.isLoadingDWOJobs = action.payload;
    },
    setIsLoadingDWOList: (state, action: PayloadAction<boolean>) => {
      state.isLoadingDWOList = action.payload;
    },
    setIsLoadingDWODetails: (state, action: PayloadAction<boolean>) => {
      state.isLoadingDWODetails = action.payload;
    },
    setIsLoadingDWOEmployeeList: (state, action: PayloadAction<boolean>) => {
      state.isLoadingDWOEmployeeList = action.payload;
    },
    setIsLoadingForemanJobs: (state, action: PayloadAction<boolean>) => {
      state.isLoadingForemanJobs = action.payload;
    },
    setIsLoadingTempWorkshifts: (state, action: PayloadAction<boolean>) => {
      state.isLoadingTempWorkshifts = action.payload;
    },
    setIsLoadingDwoJobCrew: (state, action: PayloadAction<boolean>) => {
      state.isLoadingDwoJobCrew = action.payload;
    },
    setJobsOfDWOs: (state, action: PayloadAction<JobModel[]>) => {
      state.jobs = action.payload;
    },
    setForemanJobs: (state, action: PayloadAction<JobModel[]>) => {
      state.foremanJobs = action.payload;
    },
    setLimit: (state, action: PayloadAction<number>) => {
      state.limit = action.payload;
    },
    setOrder: (state, action: PayloadAction<any>) => {
      state.order = action.payload;
    },
    setSelectedTab: (state, action: PayloadAction<number>) => {
      state.selectedTab = action.payload;
    },
    setForemanId: (state, action: PayloadAction<number | undefined>) => {
      state.foremanId = action.payload;
    },
    setDWOByForemanIdAndDate: (
      state,
      action: PayloadAction<DWOModel | undefined>,
    ) => {
      state.dwoByForemanIdAndDate = action.payload;
    },
    setTempWorkshifts: (state, action: PayloadAction<TempWorkshiftModel[]>) => {
      state.tempWorkshifts = action.payload;
    },
    setDwoJobCrew: (state, action: PayloadAction<EmployeeCrewModel[]>) => {
      state.dwoJobCrew = action.payload;
    },
    setTabSelected: (state, action: PayloadAction<TimeSheetTabs>) => {
      state.tabSelected = action.payload;
    },
    setJobPerformanceTabSelected: (state, action: PayloadAction<JobTabs>) => {
      state.jobPerformanceTabSelected = action.payload;
    },
    setCrewFilters: (state, action: PayloadAction<CrewFilters>) => {
      state.crewFilters = action.payload;
    },
    setCrewQuery: (state, action: PayloadAction<ServiceOptions>) => {
      state.crewQuery = action.payload;
    },
    setEmployeeFilters: (state, action: PayloadAction<EmployeeFilters>) => {
      state.employeeFilters = action.payload;
    },
    setEmployeeQuery: (state, action: PayloadAction<ServiceOptions>) => {
      state.employeeQuery = action.payload;
    },
    setRefreshView: (state, action: PayloadAction<boolean>) => {
      state.refreshVeiw = action.payload;
    },
  },
});

export const {
  setCount,
  setCurrentDWO,
  setCurrentDWOJob,
  setCurrentJob,
  setDWOCrewDetails,
  setDWOCrewDetailsByJobId,
  setJobHazardEmployeeData,
  setDWOJobs,
  setDWOList,
  setDWOEmployeeList,
  setInitialJobId,
  setIsLoadingDWO,
  setIsLoadingDWOJob,
  setIsLoadingDWOJobs,
  setIsLoadingDWOList,
  setIsLoadingDWODetails,
  setIsLoadingDWOEmployeeList,
  setIsLoadingForemanJobs,
  setIsLoadingTempWorkshifts,
  setIsLoadingDwoJobCrew,
  setJobsOfDWOs,
  setForemanJobs,
  setLimit,
  setOrder,
  setSelectedTab,
  setForemanId,
  setTempWorkshifts,
  setDwoJobCrew,
  setDWOByForemanIdAndDate,
  setTabSelected,
  setJobPerformanceTabSelected,
  setCrewFilters,
  setCrewQuery,
  setEmployeeFilters,
  setEmployeeQuery,
  setRefreshView,
} = dwoSlice.actions;

// Cleans the slice  when leaving Crew Details screen (TimesheetDetailsScreen)
export const cleanDWOSlice = (): AppThunk => (dispatch) => {
  dispatch(setCurrentDWO(undefined));
  dispatch(setCurrentDWOJob(undefined));
  dispatch(setCurrentJob(undefined));
  dispatch(setDWOByForemanIdAndDate(undefined));
  dispatch(setDWOCrewDetails(undefined));
  dispatch(setDWOCrewDetailsByJobId(undefined));
  dispatch(setJobsOfDWOs([]));
  dispatch(setForemanId(undefined));
};

export const approveDWO = (
  dwoJob: DWOJobModel,
  options?: ServiceOptions,
): AppThunk => async (dispatch) => {
  try {
    await dwoJobService.approveDWO(dwoJob.id as number);
    dispatch(getAllDWOCrewDetails(dwoJob.dwoId, dwoJob.jobId));
  } catch (err) {
    dispatch(
      error(
        {
          title: "Could not approve the Timesheet. Please try again.",
          message: err.message,
        },
        () => dispatch(approveDWO(dwoJob, options)),
      ),
    );
  } finally {
  }
};

export const createWorkShift = (
  workShift: WorkShiftModel,
  dwoId: number,
  jobId: number,
): AppThunk => async (dispatch) => {
  try {
    dispatch(showLoader());
    await workShiftService.create(workShift);
    dispatch(getAllDWOCrewDetails(dwoId, jobId));
    dispatch(
      getAllDWOCrewDetails(
        dwoId,
        undefined,
        undefined,
        { include: ["Region"] },
        true,
      ),
    );
  } catch (err) {
    dispatch(
      error(
        {
          title: "Could not create workShift.",
          message: err.message,
        },
        () => dispatch(createWorkShift(workShift, dwoId, jobId)),
      ),
    );
  } finally {
    dispatch(hideLoader());
  }
};

export const getAllDWOs = (options?: ServiceOptions): AppThunk => async (
  dispatch,
) => {
  try {
    dispatch(setIsLoadingDWOList(true));
    const { data, count, limit } = await dwoService.getAll(options);

    dispatch(setDWOList(data));
    dispatch(setLimit(limit));
    dispatch(setCount(count));
  } catch (e) {
    dispatch(
      error(
        {
          title: "Could not get all Timesheets",
          message: e.message,
        },
        () => dispatch(getAllDWOs(options)),
      ),
    );
  } finally {
    dispatch(setIsLoadingDWOList(false));
  }
};

export const getAllDWOCrewDetails = (
  dwoId: number | string,
  jobId?: number | string,
  dwoJobId?: number | string,
  options?: ServiceOptions,
  updateJobs?: boolean,
): AppThunk => async (dispatch) => {
  try {
    dispatch(setIsLoadingDWODetails(true));

    const { data } = await dwoService.getDWOCrewDetails(
      dwoId,
      jobId,
      dwoJobId,
      options,
    );

    dispatch(setJobHazardEmployeeData(data.dailyJobHazardAnalysis?.data.crew));

    if (data.jobs && updateJobs) {
      const supervisorsIds: number[] = data.jobs.reduce(
        (ids: number[], job: JobModel) => {
          if (job.supervisorId) {
            return ids.includes(job.supervisorId)
              ? ids
              : [...ids, job.supervisorId];
          }
          return ids;
        },
        [],
      );

      const { data: supervisorsProfiles } = await employeeService.getProfiles(
        supervisorsIds,
        undefined,
        true,
      );

      data.jobs.map(
        (job: JobModel) =>
          (job.supervisor = supervisorsProfiles.find(
            (supervisor: EmployeeModel) => supervisor.id === job.supervisorId,
          )),
      );
    }

    if (data.workShifts) {
      const employeeIds: number[] = data.workShifts.map(
        (workShift: WorkShiftModel) => workShift.employee.id as number,
      );

      const { data: crewProfiles } = await employeeService.getProfiles(
        employeeIds,
        undefined,
        true,
      );

      data.workShifts.forEach((workShift: WorkShiftModel) => {
        if (workShift.employee) {
          workShift.employee.pictureUrl = crewProfiles.find(
            (employee: EmployeeModel) =>
              (employee.id as number) === workShift.employee.id,
          )?.pictureUrl;
        }
      });
    }

    dispatch(setCurrentDWO(data));

    if (updateJobs) {
      dispatch(setJobsOfDWOs(data.jobs || []));
    }

    dispatch(setForemanId(data.foremanId));
    dispatch(jobId ? setDWOCrewDetailsByJobId(data) : setDWOCrewDetails(data));
  } catch (err) {
    dispatch(
      error(
        {
          title: "Could not fetch DWO Details. Please try again.",
          message: err.message,
        },
        () => dispatch(getAllDWOCrewDetails(dwoId, jobId, dwoJobId, options)),
      ),
    );
  } finally {
    dispatch(setIsLoadingDWODetails(false));
  }
};

export const getDWOById = (
  dwoId: number,
  options?: ServiceOptions,
): AppThunk => async (dispatch) => {
  try {
    dispatch(setIsLoadingDWO(true));
    const { data } = await dwoService.getById(dwoId, options);

    if (data) {
      dispatch(setCurrentDWO(data));
      return;
    }

    dispatch(setCurrentDWO(undefined));
  } catch (err) {
    dispatch(
      error(
        {
          title: "Could not fetch DWO Details. Please try again.",
          message: err.message,
        },
        () => dispatch(getDWOById(dwoId, options)),
      ),
    );
  } finally {
    dispatch(setIsLoadingDWO(false));
  }
};

export const getDWOIdByForemanIdAndDate = (
  foremanId: number,
  date: string,
): AppThunk => async (dispatch) => {
  try {
    const { data } = await dwoService.getDWOByForemanIdAndDate(foremanId, date);

    dispatch(setDWOByForemanIdAndDate(data));
  } catch (err) {
    dispatch(
      error(
        {
          title: "Could not get a DWO for the selected date.",
          message:
            "Please try selecting a different date or creat a DWO for this date",
          actionButtonText: "Create DWO",
        },
        () => dispatch(createDWOIdByForemanIdAndDate(foremanId, date)),
      ),
    );
  }
};

export const createDWOIdByForemanIdAndDate = (
  foremanId: number,
  date: string,
): AppThunk => async (dispatch) => {
  try {
    const { data: hazardData } = await dailyJobHazardService.create({
      data: "",
      date,
      isSigned: false,
    });
    const { data } = await dwoService.create({
      dailyJobHazardAnalysisId: hazardData.id,
      date,
      description: "Empty DWO",
      foremanId,
      status: "submitted",
      searchData: "",
      vehicleDetails: {},
    });
    dispatch(setDWOByForemanIdAndDate(data));
  } catch (err) {
    dispatch(
      error(
        {
          title: "Could not create DWO for the selected date.",
          message: "Please try again",
          actionButtonText: "Retry",
        },
        () => {
          dispatch(createDWOIdByForemanIdAndDate(foremanId, date));
        },
      ),
    );
  }
};

export const createEmptyDWO = (
  crew: CrewModel,
  dwo: DWOModel,
  dwoJobData: DWOJobModel,
): AppThunk => async (dispatch) => {
  try {
    dispatch(showLoader());
    const { data: crewData } = await crewService.getAll({
      where: { id: { $eq: crew.id } },
      include: [{ EmployeeCrew: ["employee"] }],
    });

    if (crewData.length > 0 && crewData[0].employeeCrews) {
      const crewMembers = crewData[0].employeeCrews.filter(
        (employeeCrew: EmployeeCrewModel) =>
          employeeCrew.isCurrent &&
          !employeeCrew.isSupervisor &&
          employeeCrew.employee?.isActive,
      );
      crewData[0].employeeCrews = crewMembers;
      const workshiftReqs = crewMembers.map(async (crewMember) => {
        const currentDate = new Date(dwo.date).toISOString();
        return await workShiftService.customCreate({
          dwoJobId: dwoJobData.id as number,
          employeeId: crewMember.employee?.id as number,
          end: currentDate,
          endLocation: LOCATION,
          endPictureMatchStatus: WorkShiftPictureMatchStatus.PENDING,
          hours: 0,
          isDriver: crewMember.isDriver,
          start: currentDate,
          startLocation: LOCATION,
          startPictureMatchStatus: WorkShiftPictureMatchStatus.PENDING,
          status: WorkShiftStatus.FINISHED,
          timeBreaks: [],
          type: "normal",
          wasTemporal: false,
        });
      });
      await Promise.all(workshiftReqs);
    }
    dispatch(setRefreshView(true));
  } catch (e) {
    dispatch(
      error(
        {
          title: "Could not create workshifts",
          message: e.message,
        },
        () => dispatch(createEmptyDWO(crew, dwo, dwoJobData)),
      ),
    );
  } finally {
    dispatch(hideLoader());
    window.location.reload();
  }
};

export const createEmptyTimesheet = (
  crew: CrewModel,
  date: string,
  jobId: number,
  foremanId: number,
): AppThunk => async (dispatch) => {
  let dwoData = null;
  try {
    const { data } = await dwoService.getDWOByForemanIdAndDate(foremanId, date);
    dwoData = data;
  } catch (err) {}
  try {
    if (dwoData) {
      return dwoData.id;
    } else {
      const { data: hazardData } = await dailyJobHazardService.create({
        data: "",
        date,
        isSigned: false,
      });
      const { data } = await dwoService.create({
        dailyJobHazardAnalysisId: hazardData.id,
        date,
        description: "Empty DWO",
        foremanId,
        status: "submitted",
        searchData: "",
        vehicleDetails: {},
      });
      const { data: dwoJobData } = await dwoJobService.create({
        crewId: Number(crew.id),
        dwoId: Number(data.id),
        jobId,
        status: "submitted",
      });
      dispatch(createEmptyDWO(crew, data, dwoJobData));
      dispatch(setDWOByForemanIdAndDate(data));
      return data.id;
    }
  } catch (err) {
    dispatch(
      error(
        {
          title: "Could not create DWO.",
          message: "Please try again",
          actionButtonText: "Retry",
        },
        () => {
          dispatch(createEmptyTimesheet(crew, date, jobId, foremanId));
        },
      ),
    );
  }
};

export const getForemanJobs = (employeeId: number): AppThunk => async (
  dispatch,
) => {
  try {
    dispatch(setIsLoadingForemanJobs(true));
    const { data } = await jobService.getEmployeeJobs(employeeId, {
      where: {
        $or: [
          { status: JobStatusValues.IN_PROGRESS },
          { status: JobStatusValues.ASSIGNED },
        ],
      },
    });
    dispatch(setForemanJobs(data));
  } catch (e) {
    dispatch(
      error(
        {
          title: "Could not retrieve foreman jobs",
          message: e.message,
        },
        () => dispatch(getForemanJobs(employeeId)),
      ),
    );
  } finally {
    dispatch(setIsLoadingForemanJobs(false));
  }
};

export const createWorkshifts = (
  dwo: DWOModel,
  jobs: JobModel[],
): AppThunk => async (dispatch) => {
  try {
    dispatch(showLoader());
    jobs.forEach(async (job, currentIndex) => {
      // TODO: the default coordinates should be a yard by region
      // const { data: regionData } = await regionService.getById(job.regionId);
      //  "32.65722450278615,-97.28580707104494" Current hard coded location
      const { data: crewData } = await crewService.getAll({
        where: { foremanId: { $eq: dwo.foremanId }, jobId: { $eq: job.id } },
        include: [{ EmployeeCrew: ["employee"] }],
      });

      if (
        crewData.length > 0 &&
        crewData[0].employeeCrews &&
        crewData[0].employeeCrews.length > 0
      ) {
        const crewMembers = crewData[0].employeeCrews.filter(
          (employee: EmployeeCrewModel) =>
            employee.isCurrent && !employee.isSupervisor,
        );
        crewData[0].employeeCrews = crewMembers;

        const { data: dwoJobData } = await dwoJobService.create({
          crewId: crewData[0].id as number,
          dwoId: dwo.id as number,
          endLocation: LOCATION,
          jobId: job.id as number,
          startLocation: LOCATION,
          status: "submitted",
        });

        if (dwoJobData.id) {
          const workshiftReqs = crewMembers.map(async (crewMember) => {
            const currentDate = new Date(dwo.date).toISOString();
            return await workShiftService.customCreate({
              dwoJobId: dwoJobData.id as number,
              employeeId: crewMember.employee?.id as number,
              end: currentDate,
              endLocation: LOCATION,
              endPictureMatchStatus: WorkShiftPictureMatchStatus.PENDING,
              hours: 0,
              isDriver: crewMember.isDriver,
              start: currentDate,
              startLocation: LOCATION,
              startPictureMatchStatus: WorkShiftPictureMatchStatus.PENDING,
              status: WorkShiftStatus.FINISHED,
              timeBreaks: [],
              type: "normal",
              wasTemporal: false,
            });
          });

          await Promise.all(workshiftReqs);

          if (currentIndex === jobs.length - 1) {
            dispatch(setRefreshView(true));
          }
        }
      }
    });
  } catch (e) {
    dispatch(
      error(
        {
          title: "Could not create workshifts",
          message: e.message,
        },
        () => dispatch(createWorkshifts(dwo, jobs)),
      ),
    );
  } finally {
    dispatch(hideLoader());
  }
};

export const createSwapWorkshift = (
  workshift: WorkShiftRequestModel,
  tempWorkshift: TempWorkshiftModel,
  dwoId: number,
  jobId: number,
  wasTemporal: boolean,
): AppThunk => async (dispatch) => {
  try {
    await workShiftService.customCreate(workshift);

    dispatch(deleteTempWorkshift(tempWorkshift));
    dispatch(
      getAllDWOCrewDetails(dwoId, jobId, workshift.dwoJobId, {
        order: undefined,
      }),
    );
  } catch (e) {
    dispatch(
      error(
        {
          title: "Could not create workshift",
          message: e.message,
        },
        () =>
          dispatch(
            createSwapWorkshift(
              workshift,
              tempWorkshift,
              dwoId,
              jobId,
              wasTemporal,
            ),
          ),
      ),
    );
  }
};

export const getDWOJobById = (
  dwoId: number,
  options?: ServiceOptions,
): AppThunk => async (dispatch) => {
  try {
    dispatch(setIsLoadingDWOJob(true));
    const { data } = await dwoJobService.getById(dwoId, options);

    if (data) {
      // TODO: Remove the user request, this is just to update updetedBy employee
      // while the back fixes the issue with that endpoint
      const { data: userData } = await userService.getById(
        data.updatedById as number,
        {
          include: ["employee"],
        },
      );
      const updatedData = cloneDeep(data);
      updatedData.updatedBy = { employee: userData.employee };
      // ---------------------------------------------------------------

      dispatch(setCurrentDWOJob(updatedData));
      return;
    }

    dispatch(setCurrentDWOJob(undefined));
  } catch (err) {
    dispatch(
      error(
        {
          title: "Could not fetch DWO Job Details. Please try again.",
          message: err.message,
        },
        () => dispatch(getDWOJobById(dwoId, options)),
      ),
    );
  } finally {
    dispatch(setIsLoadingDWOJob(false));
  }
};

export const getDWOEmployeeList = (
  options?: ServiceOptions,
): AppThunk => async (dispatch) => {
  try {
    dispatch(setIsLoadingDWOEmployeeList(true));
    const { count, data, limit } = await dwoService.getAllDWOEmployees(options);

    dispatch(setDWOEmployeeList(data));
    dispatch(setCount(count));
    dispatch(setLimit(limit));
  } catch (err) {
    dispatch(
      error(
        {
          title: "Could not retrieve Employees DWOs List. Please try again.",
          message: err.message,
        },
        () => dispatch(getDWOEmployeeList(options)),
      ),
    );
  } finally {
    dispatch(setIsLoadingDWOEmployeeList(false));
  }
};

export const updateWorkShifts = (
  workShifts: Record<"workshifts", WorkShiftModel[]>,
  dwoId: number,
  jobId?: number,
  updatedExistingWorkShifts?: WorkShiftModel[],
): AppThunk => async (dispatch) => {
  try {
    dispatch(showLoader());
    await workShiftService.updateWorkShifts(workShifts);
    if (updatedExistingWorkShifts) {
      for (const workshift of updatedExistingWorkShifts) {
        const now = moment(workshift.date);
        const [hours, minutes] = workshift.lunchTime.split(":");
        if (parseInt(hours) !== 0 || parseInt(minutes) !== 0) {
          await timeBreakService.create({
            createdById: workshift.createdById,
            end: now
              .add(parseInt(hours), "hours")
              .add(parseInt(minutes), "minutes")
              .toDate(),
            start: moment(workshift.date).toDate(),
            status: TimeBreakStatus.FINISHED,
            type: TimeBreakType.LUNCH,
            phaseCode: workshift.phaseCode!,
            updatedById: workshift.updatedById,
            workShiftId: workshift.id,
          });
        }
        for (const timeBreakId of workshift.timeBreaksIds ?? []) {
          await timeBreakService.delete(timeBreakId);
        }
      }
    }
    dispatch(getAllDWOCrewDetails(dwoId, jobId));
    dispatch(
      getAllDWOCrewDetails(
        dwoId,
        undefined,
        undefined,
        { include: ["Region"] },
        true,
      ),
    );
  } catch (err) {
    if (err.toString().includes("400")) {
      dispatch(
        error(
          {
            title: "Could not update workShifts.",
            message:
              "Make sure Start is earlier than End, or End is later than Start",
            hideCancelButton: true,
            actionButtonText: "Ok",
          },
          noop,
        ),
      );

      return;
    }

    dispatch(
      error(
        {
          title: "Could not update workShifts.",
          message: err.message,
        },
        () => dispatch(updateWorkShifts(workShifts, dwoId, jobId)),
      ),
    );
  } finally {
    dispatch(hideLoader());
  }
};

export const updateDWOForTimeOff = (
  dwoId: number,
  dwoJobIds: { dwoJobIds: number[] },
): AppThunk => async (dispatch) => {
  try {
    dispatch(showLoader());
    await dwoService.updateDWOForTimeOff(dwoId, dwoJobIds);
    dispatch(
      getAllDWOCrewDetails(
        dwoId,
        undefined,
        undefined,
        { include: ["Region"] },
        true,
      ),
    );
  } catch (err) {
    dispatch(
      error(
        {
          title: "Could not update DWO for Time Off.",
          message: err.message,
        },
        () => dispatch(updateDWOForTimeOff(dwoId, dwoJobIds)),
      ),
    );
  } finally {
    dispatch(hideLoader());
  }
};

export const getAllTempWorkshift = (dwoJobId: number): AppThunk => async (
  dispatch,
) => {
  dispatch(setIsLoadingTempWorkshifts(true));
  try {
    const query: ServiceOptions = { where: { dwoJobId } };
    const { data } = await tempWorkshiftService.getAll(query);
    dispatch(setTempWorkshifts(data));
  } catch (err) {
    dispatch(
      error(
        {
          title: "Could not create workShift.",
          message: err.message,
        },
        () => dispatch(getAllTempWorkshift(dwoJobId)),
      ),
    );
  } finally {
    dispatch(setIsLoadingTempWorkshifts(false));
  }
};

export const createTempWorkshift = (
  tempWorkshift: TempWorkshiftModel,
): AppThunk => async (dispatch) => {
  try {
    await tempWorkshiftService.create(tempWorkshift);
    dispatch(getAllTempWorkshift(tempWorkshift.dwoJobId));
  } catch (err) {
    dispatch(
      error(
        {
          title: "Could not create workShift.",
          message: err.message,
        },
        () => dispatch(createTempWorkshift(tempWorkshift)),
      ),
    );
  }
};

export const updateTempWorkshift = (
  tempWorkshift: TempWorkshiftModel,
): AppThunk => async (dispatch) => {
  try {
    await tempWorkshiftService.update(
      tempWorkshift.id as number,
      tempWorkshift,
    );
    dispatch(getAllTempWorkshift(tempWorkshift.dwoJobId));
  } catch (err) {
    dispatch(
      error(
        {
          title: "Could not edit workShift.",
          message: err.message,
        },
        () => dispatch(updateTempWorkshift(tempWorkshift)),
      ),
    );
  }
};

export const deleteTempWorkshift = (
  tempWorkshift: TempWorkshiftModel,
): AppThunk => async (dispatch) => {
  try {
    await tempWorkshiftService.delete(tempWorkshift.id as number);
    dispatch(getAllTempWorkshift(tempWorkshift.dwoJobId));
  } catch (err) {
    dispatch(
      error(
        {
          title: "Could not delete workShift.",
          message: err.message,
        },
        () => dispatch(deleteTempWorkshift(tempWorkshift)),
      ),
    );
  }
};

export const getDwoJobCrew = (crewId: number): AppThunk => async (dispatch) => {
  try {
    dispatch(setIsLoadingDwoJobCrew(true));
    const query: ServiceOptions = {
      include: [{ EmployeeCrew: ["Employee"] }],
    };
    const { data } = await crewService.getById(crewId, query);

    if (data.employeeCrews) {
      const employeeIds: number[] = data.employeeCrews.map(
        (crewMember: EmployeeCrewModel) => crewMember.employee?.id as number,
      );

      const { data: crewProfiles } = await employeeService.getProfiles(
        employeeIds,
        undefined,
        true,
      );

      data.employeeCrews.forEach((crewMember: EmployeeCrewModel) => {
        if (crewMember.employee) {
          crewMember.employee.pictureUrl = crewProfiles.find(
            (employee: EmployeeModel) =>
              (employee.id as number) === crewMember.employee?.id,
          )?.pictureUrl;
        }
      });

      const employeeCrews = data.employeeCrews;
      const selectableCrewMembers = employeeCrews.filter(
        (member) => member.isCurrent && !member.isSupervisor,
      );

      dispatch(setDwoJobCrew(selectableCrewMembers));
    }
  } catch (err) {
    dispatch(
      error(
        {
          title: "Could not get crew members",
          message: err.message,
        },
        () => dispatch(getDwoJobCrew(crewId)),
      ),
    );
  } finally {
    dispatch(setIsLoadingDwoJobCrew(false));
  }
};

export const selectSelectedTab = (state: RootState) => state.dwo.selectedTab;
export const selectCount = (state: RootState) => state.dwo.count;
export const selectCurrentDWO = (state: RootState) => state.dwo.currentDWO;
export const selectCurrentDWOJob = (state: RootState) =>
  state.dwo.currentDWOJob;
export const selectCurrentJob = (state: RootState) => state.dwo.currentJob;
export const selectDWOList = (state: RootState) => state.dwo.dwoList;
export const selectDWOCrewDetails = (state: RootState) =>
  state.dwo.dwoCrewDetails;
export const selectDWOCrewDetailsByJobId = (state: RootState) =>
  state.dwo.dwoCrewDetailsByJobId;
export const selectJobHazardEmployeeData = (state: RootState) =>
  state.dwo.jobHazardEmployeeData;
export const selectDWOJobs = (state: RootState) => state.dwo.dwoJobs;
export const selectDWOEmployeeList = (state: RootState) =>
  state.dwo.dwoEmployeeList;
export const selectInitialJobId = (state: RootState) => state.dwo.initialJobId;
export const selectIsLoadingDWO = (state: RootState) => state.dwo.isLoadingDWO;
export const selectIsLoadingDWOJob = (state: RootState) =>
  state.dwo.isLoadingDWOJob;
export const selectIsLoadingDWODetails = (state: RootState) =>
  state.dwo.isLoadingDWODetails;
export const selectIsLoadingDWOJobs = (state: RootState) =>
  state.dwo.isLoadingDWOJobs;
export const selectIsLoadingDWOList = (state: RootState) =>
  state.dwo.isLoadingDWOList;
export const selectIsLoadingDWOEmployeeList = (state: RootState) =>
  state.dwo.isLoadingDWOEmployeeList;
export const selectIsLoadingForemanJobs = (state: RootState) =>
  state.dwo.isLoadingForemanJobs;
export const selectIsLoadingTempWorkshifts = (state: RootState) =>
  state.dwo.isLoadingTempWorkshifts;
export const selectIsLoadingDwoJobCrew = (state: RootState) =>
  state.dwo.isLoadingDwoJobCrew;
export const selectTempWorkshifts = (state: RootState) =>
  state.dwo.tempWorkshifts;
export const selectJobsOfDWOs = (state: RootState) => state.dwo.jobs;
export const selectForemanJobs = (state: RootState) => state.dwo.foremanJobs;
export const selectLimit = (state: RootState) => state.dwo.limit;
export const selectOrder = (state: RootState) => state.dwo.order;
export const selectForemanId = (state: RootState) => state.dwo.foremanId;
export const selectDWOByForemanIdAndDate = (state: RootState) =>
  state.dwo.dwoByForemanIdAndDate;
export const selectDWOJobCrew = (state: RootState) => state.dwo.dwoJobCrew;
export const selectTimesheetTabSelected = (state: RootState) =>
  state.dwo.tabSelected;
export const selectJobPerformanceTabSelected = (state: RootState) =>
  state.dwo.jobPerformanceTabSelected;
export const selectCrewFilters = (state: RootState) => state.dwo.crewFilters;
export const selectCrewQuery = (state: RootState) => state.dwo.crewQuery;
export const selectEmployeeFilters = (state: RootState) =>
  state.dwo.employeeFilters;
export const selectEmployeeQuery = (state: RootState) =>
  state.dwo.employeeQuery;
export const selectRefreshView = (state: RootState) => state.dwo.refreshVeiw;

export default dwoSlice.reducer;
